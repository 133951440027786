<template>
  <v-card width="95%" style="margin: 15px auto" v-if="layerLoaded">
    <v-card-title class="primary white--text justify-center">
      Отчет по слою
    </v-card-title>
    <v-card-text>
      <div class="title text-center pt-4">Общая информация</div>
      <v-simple-table class="table-last-column-right" dense>
        <tbody>
          <tr>
            <td>Слой</td>
            <td>{{getLayerNameById(layer.layerNameId)}}</td>
          <tr>
            <td>Номер</td>
            <td>{{layer.num}}</td>
          </tr>
          <tr>
            <td>Диаметр электрода, мм</td>
            <td>{{modes.electrode}}</td>
          </tr>
          <tr>
            <td>Полярность тока</td>
            <td>{{getPolarityNameById(modes.polarityNameId)}}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Временные характеристики слоя</div>
      <v-simple-table class="table-last-column-right" dense>
        <tbody>
          <tr>
            <td>Дата начала</td>
            <td>{{ secToDate(layer.startedAt) }}</td>
          </tr>
          <tr>
            <td>Время начала</td>
            <td>{{ secToTime(layer.startedAt) }}</td>
          </tr>
          <tr>
            <td>Дата окончания</td>
            <td>{{ secToDate(layer.finishedAt) }}</td>
          </tr>
          <tr>
            <td>Время окончания</td>
            <td>{{ secToTime(layer.finishedAt) }}</td>
          </tr>
          <tr>
            <td>Общее время выполнения</td>
            <td>{{ secToHoursTime(layer.finishedAt - layer.startedAt) }}</td>
          </tr>
          <tr>
            <td>Время сварки слоя (чистое время горения сварочной дуги)</td>
            <td>{{ secToHoursTime(layer.weldTime / 1000) }}</td>
          </tr>
          <tr>
            <td>Время дополнительных операций</td>
            <td>
              {{secToHoursTime(
                (layer.finishedAt - layer.startedAt) -
                (layer.weldTime / 1000)
              )}}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Суммарные отклонения</div>
      <v-treeview
        class="bottom-border"
        :items="layerDevs"
        :open-on-click="true"
        >
      </v-treeview>
      <div class="title text-center pt-4">Поканальные отклонения</div>
      <template v-for="chInfo in channelsInfs">
        <div
          class="my-4 px-2 bottom-border"
          :key="chInfo[0].channelId + ' ' + chInfo[0].id"
          >
          <div class="subheader text-center">{{getChannelNameById(chInfo[0].channelId)}}</div>
          <div class="subheader">Режим по карте min: {{chInfo[0].min}}</div>
          <div class="subheader">Режим по карте max: {{chInfo[0].max}}</div>
          <div class="subheader">Измеренное среднее: {{chInfo[0].avr}}</div>
          <v-treeview
            :items="chInfo"
            :open-on-click="true"
            >
          </v-treeview>
        </div>
      </template>
      <div class="title text-center pt-4">Регистограммы</div>
      <div class="text-center" v-if="layer.regFile">
        <div ref="charts"></div>
        <v-btn
          color="success"
          :to="'/api/manufacture/process-card-layer/download-reg-file?path=' + layer.regFile"
          target="_blank"
          >
          скачать
        </v-btn>
      </div>
      <div v-else>
        <div class="subheading text-center">Регистограммы загружаются</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Highcharts from 'highcharts/highstock'
import { secToDate } from '@/libs/time.js'
import { secToTime } from '@/libs/time.js'
import { secToHoursTime } from '@/libs/time.js'
import { prepareDevs } from '../../libs/helpers.js'
import { getChannelNameById } from '@/libs/common.js'
import { getLayerNameById } from '@/libs/common.js'
import { getPolarityNameById } from '@/libs/common.js'
import { toPercentString } from '@/libs/common.js'
import { mapState } from 'vuex'
import pako from 'pako'

export default {
  data: () => ({
    secToTime,
    secToDate,
    secToHoursTime,
    prepareDevs,
    getChannelNameById,
    getLayerNameById,
    getPolarityNameById,
    toPercentString,
    layerLoaded: false,
    modesLoaded: false,
    layer: {},
    modes: {},
    layerDevs: [],
    channelsInfs: [],
    showCharts: false,
    loadCharts: false,
    sending: false,
    chart: null,
  }),
  computed: {
    ...mapState('common', { modules: state => state.modules})
  },
  methods: {
    loadRegFile() {
      // this.showCharts = true
      // this.loadCharts = true
      let dataUrl = '/manufacture/process-card-layer/download-reg-file'
      this.$axios.get(dataUrl, {
        params: { path: this.layer.regFile},
        responseType: 'arraybuffer'
      }).then(r => {
        this.prepareCharts(r.data).then(options => {
          //this.loadCharts = false
          this.chart = Highcharts.stockChart(this.$refs.charts, options)
        }).catch(() => {})
      }).catch(e => {
        //this.showCharts = false
        //this.loadCharts = false
        //console.log(e)
      })
    },
    prepareCharts(data) {
      var self = this
      const options = {
        rangeSelector: {
          enabled: false,
        },
        chart: {
          type: 'spline'
        },
        yAxis: [
          { title: { text: 'Ток' }, opposite: false, crosshair: true },
          { title: { text: 'Напряжение' }, opposite: true, crosshair: true },
        ],
        tooltip: {
          headerFormat: '',
          valueDecimals: 1,
          split: false
        },
        series: [],
        legend: {
          enabled: true,
          align: 'center',
          verticalAlign: 'top',
        },
      }
      return new Promise((resolve, reject) => {
        const iSeries = {
          name: 'Ток',
          //pointInterval: 1,
          data: []
        }
        const uSeries = {
          name: 'Напряжение',
          //pointInterval: 1,
          yAxis: 1,
          data: [],
        }
        const d = pako.inflate(data, { to: 'string' })
        var lines = d.split('\n')
        lines.forEach(function(line, lineNo) {
          if(lineNo != 0) {
            if(line) {
              var items = line.split(';')
              let timeParts = items[0].split('.')
              let t = (parseInt(timeParts[0])*1000) + parseInt(timeParts[1])
              iSeries.data.push([t, parseFloat(items[1])])
              uSeries.data.push([t, parseFloat(items[2])])
            }
          }
        })
        options.series.push(iSeries)
        options.series.push(uSeries)
        resolve(options)
      })
    }
  },
  mounted: function() {
    let url = '/manufacture/process-card-layer/view'
    this.$axios.get(url, { params: { id: this.$route.query.id}}).then(res => {
      this.layer = res.data
      let moduleId = Number(this.layer.processCard.card.moduleId)
      let m = this.modules.find(mItem => mItem.id === moduleId)
      this.layerDevs.push(this.prepareDevs(this.layer, this.layer.weldTime))
      this.layerLoaded = true
      if (m) {
        let cardUrl = m.baseUrl + '/card/modes'
        this.$axios.get(cardUrl, { params: {
          cardUuid: this.layer.processCard.card.cardUuid,
          layerNum: this.layer.num
        }}).then(res => {
          this.modes = Object.assign({}, res.data)
          console.log(this.modes)
          this.layer.channelsDeviations.forEach(chDevs => {
            console.log(chDevs)
            let lm = this.modes.channels.find(mItem => {
              console.log('lm',mItem.channelId,chDevs.channelId)
              return Number(mItem.channelId) == Number(chDevs.channelId)
            })
            let la = this.layer.channelsAverages.find(aItem => {
              console.log('la',aItem.channelId,chDevs.channelId)
              return Number(aItem.channelId) == Number(chDevs.channelId)
            })
            console.log(lm,la)
            this.channelsInfs.push([Object.assign(
              {},
              this.prepareDevs(chDevs, this.layer.weldTime),
              {
                channelId: chDevs.channelId,
                min: lm ? lm.min : 'не задан',
                max: lm ? lm.max : 'не задан',
                avr: (la.integrator / la.samplesCount).toFixed(1)
              }
            )])
          })
          this.loadRegFile()
          this.modesLoaded = true
        })
        .catch(() => {})
      } else {
        console.log("Card module not found")
      }
    }).catch(() => {})
  }

          // this.mode.channels.forEach(item_mode => {
          //   let dev = this.layer.devs.find(item_dev => item_dev.channelId === item_mode.channelId)
          //   let avr = this.layer.avrs.find(item_avr => item_avr.channelId === item_mode.channelId)
          //   this.channelsInfo.push({...item_mode,...dev,...avr})
          // })
}
</script>

<style>
  .table-last-column-right tbody tr td:last-child {
    text-align: right;
  }
  .table-text-center {
    text-align: center;
  }
  .table-text-center th {
    text-align: center !important;
  }
  .bottom-border {
    border-bottom-style: solid;
    border-bottom-width: thin;
  }
</style>
